import React from 'react'
import Layout from '../components/layout'
import {Link} from "gatsby";

const NotFoundPage = () => (
  <Layout>
      <div id="main">
          <section id="content" className="main">
            <h1>EI LÖYDY</h1>
            <p>Etsimääsi sivua ei löydy</p>

              <footer className="major">
                  <ul className="actions">
                      <li>
                          <Link to="/" className="button special">
                              Takaisin etusivulle
                          </Link>
                      </li>
                  </ul>
              </footer>
          </section>


      </div>


  </Layout>
)

export default NotFoundPage
